//作废申请弹框
<template>
  <el-dialog
    :close-on-click-modal="false"
    title="作废申请"
    :visible.sync="show"
    width="800px"
  >
    <div v-loading="loading">
      <el-form ref="form" :model="params" :rules="rules" label-width="100px">
        <el-form-item label="作废单据号:">
          <div>{{ currentItem.orderCode }}</div>
        </el-form-item>
        <el-form-item label="作废原因:" prop="reason">
          <el-input
            v-model="params.reason"
            placeholder="请输入作废原因"
            type="textarea"
            maxlength="100"
            :autosize="{ minRows: 2 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-divider content-position="left">审批流程</el-divider>
        <div v-if="nodes && nodes.length > 0" style="margin-top: 20px">
          <el-timeline :hide-timestamp="true">
            <el-timeline-item
              v-for="(node, index) in nodes"
              :key="index"
              v-if="node.userType != 1"
            >
              <div>
                <span>{{ node.title }}&emsp;</span>
                <el-button
                  size="mini"
                  type="primary"
                  v-if="node.assigneeType == 'SELF_SELECT'"
                  @click="addUser(node)"
                  style="padding: 2px 8px"
                >
                  添加
                </el-button>

                <el-tag
                  size="mini"
                  style="margin-left: 10px"
                  v-if="node.users.length > 1 && node.mode == 'ORDERLY'"
                >
                  依次审批
                </el-tag>
                <el-tag
                  size="mini"
                  style="margin-left: 10px"
                  v-if="node.users.length > 1 && node.mode == 'DISORDER'"
                >
                  多人审批
                </el-tag>
                <el-tag
                  size="mini"
                  style="margin-left: 10px"
                  v-if="node.users.length > 1 && node.mode == 'OR'"
                >
                  一人审批
                </el-tag>
              </div>
              <div style="margin-left: 20px; margin-top: 10px">
                <span>{{ getNicknames(node.users) }}</span>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <user-picker
          ref="userPicker"
          :multiple="selectMultiple"
          :selected="selectUsers"
          @ok="selected"
        ></user-picker>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm" v-loading="btnLoading">
        提交申请
      </el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '../../../mixins/dialogCommonParams.js'
import UserPicker from '../../flowable/components/UserPicker.vue'
export default {
  mixins: [dialogCommonParams],
  components: { UserPicker },
  data() {
    return {
      btnLoading: false,
      params: {},
      rules: {
        reason: [{ required: true, message: '请输入作废原因' }]
      },
      nodes: [],
      selectNode: '', // 选中的节点
      selectMultiple: false,
      selectUsers: [],
      auditors: {},
      variables: {}
    }
  },
  created() {},
  methods: {
    async submitForm() {
      try {
        if (this.btnLoading) return
        const valid = await this.$refs['form'].validate()
        if (!valid) return
        for (let i = 0; i < this.nodes.length; i++) {
          if (
            this.nodes[i].assigneeType == 'SELF_SELECT' &&
            this.nodes[i].userType == 2 &&
            this.nodes[i].users?.length == 0 &&
            !this.auditors[this.nodes[i].id]?.length
          ) {
            return this.$message.warning('请选择审批人后提交')
          }
        }
        this.btnLoading = true
        await this.$api.apply.invalidated({
          ...this.params,
          orderCode: this.currentItem.orderCode,
          auditors: this.auditors
        })
        this.$message.success('作废申请已提交，通过我的申请可查看申请进度。')
        this.$emit('updateList')
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    },
    // 获取流程信息
    async init() {
      try {
        this.loading = true
        const { processCode } = this.currentItem
        const infoRes = await this.$api.apply.applyResult({ processCode })
        const res = await this.$api.flowable.listAssignedUsers({
          processInstanceId: infoRes.processId
        })
        this.nodes = res?.nodes || []
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    addUser(node) {
      this.selectNode = node
      this.selectMultiple = node.multiple
      this.selectUsers = []
      this.selectUsers = this.auditors[this.selectNode.id]
      this.$refs.userPicker.show()
    },
    selected(select) {
      const _this = this
      this.auditors[this.selectNode.id] = select
      this.nodes = this.nodes.map(item => {
        if (item.id == _this.selectNode.id) {
          let exist = item.users.filter(val => val.status || val.status == 0)
          let existUserIds = exist.map(item => item.id)
          _this.auditors[_this.selectNode.id] = select.filter(
            _item => !existUserIds.includes(_item.id)
          )
          item.users = exist.concat(_this.auditors[_this.selectNode.id])
        }
        return item
      })
    },
    getNicknames(arr) {
      return (arr || []).map(a => a.nickname).join('、')
    }
  }
}
</script>
