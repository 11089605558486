//收货确认弹框
<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="currentItem.deliveryFlag == 1 ? '收货确认' : '收货详情'"
    :visible.sync="show"
    width="800px"
    v-loading="loading"
  >
    <el-form ref="form" :model="params" :rules="rules">
      <el-table :data="tableData">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="code"
          label="料号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="name"
          label="物品名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="申请数量"
        >
          <template slot-scope="{ row }">
            {{ row.num | dividedInto }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          :label="currentItem.deliveryFlag == 1 ? '待收货数量' : '收货数量'"
        >
          <template slot-scope="{ row }">
            {{ currentItem.deliveryFlag == 1 ? (row.waitReceiveNum | dividedInto) : ((row.num - row.waitReceiveNum) | dividedInto) }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="本次收货数量"
		  v-if="currentItem.deliveryFlag == 1"
        >
          <template slot-scope="{ row, $index }">
            <el-form-item
              label=""
              label-width="0"
              :prop="'confirmDeliveryInputs.' + $index + '.confirmNum'"
            >
              <el-input
                v-model="params.confirmDeliveryInputs[$index].confirmNum"
                placeholder="请输入数量"
                @input="
                  val =>
                    (params.confirmDeliveryInputs[$index].confirmNum =
                      $format.fmtNumber(val))
                "
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
	  <el-form-item label="备注" prop="remark" v-if="currentItem.deliveryFlag == 1">
	    <el-input v-model="remark" placeholder="请输入备注" :readonly="currentItem.deliveryFlag != 1"></el-input>
	  </el-form-item>
	  <div v-else>
		  <div style="margin: 20px auto;">收货完成时间：{{currentItem.receiveTime}}</div>
		  <div>备注：{{currentItem.receiveRemark || '--'}}</div>
	  </div>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="currentItem.deliveryFlag == 1">
      <el-button type="primary" @click="submitForm">确定</el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
	<span slot="footer" class="dialog-footer" v-else>
		<el-button @click="show = false">关闭</el-button>
	</span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '../../../mixins/dialogCommonParams.js'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      tableData: [],
	  remark: '',
      params: { confirmDeliveryInputs: [] },
      rules: {
        confirmNum: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let index = rule.field?.split('.')[1]
              let maxOutNum = this.tableData[index].waitReceiveNum
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else if (value && Number(value) > Number(maxOutNum)) {
                callback(`收货数量最多${maxOutNum}`)
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        let { processCode, type } = this.currentItem
        const list = await this.$api.apply.detailList({
          processCode,
          type
        })
        this.tableData = list
        this.params.confirmDeliveryInputs = list.map(a => {
          return { id: a.id, confirmNum: '' }
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    submitForm() {
      try {
        if (this.loading) return
        this.$refs['form'].validate(async valid => {
          if (valid) {
            let { confirmDeliveryInputs } = this.params
            let hasNum = false
            confirmDeliveryInputs = confirmDeliveryInputs.map(a => {
              let num = Number(a.confirmNum)
              if (num > 0) hasNum = true
              return { ...a, confirmNum: num || 0 }
            })
            if (!hasNum) {
              this.$message.warning('至少收货确认一条数据')
              return
            }
            confirmDeliveryInputs = confirmDeliveryInputs.filter(
              a => a.confirmNum > 0
            )
            this.loading = true
            let data = await this.$api.apply.confirmDelivery(
			{
				processCode: this.currentItem.processCode,
				remark: this.remark
			},
              confirmDeliveryInputs
            )
            if (data) {
              this.$message.success('提交成功')
              this.$emit('updateList')
              this.show = false
            } else {
              this.$message.error('U8未审核通过，无法确认收货')
            }
          }
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-table {
  td.el-table__cell .el-form-item {
    margin-bottom: 0;
    &.is-error {
      margin-bottom: 22px;
    }
  }
}
</style>
