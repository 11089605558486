//我的申请
<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="申请单号" prop="orderCode">
          <el-input
            v-model="params.orderCode"
            clearable
            placeholder="请输入申请单号"
          />
        </el-form-item>
        <el-form-item label="业务类型" prop="type">
          <el-select v-model="params.type" clearable placeholder="请选择">
            <el-option
              v-for="item in $constant.applyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-select v-model="params.status" clearable placeholder="请选择">
            <el-option
              v-for="item in $constant.auditStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column align="center" label="序号" type="index" width="60" />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="orderCode"
          label="申请单号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="typeStr"
          label="业务类型"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="申请时间"
        >
          <template slot-scope="{ row }">
            {{ row.applyDate | date('YYYY-mm-dd HH:MM:SS') }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="auditStatusStr"
          label="审批状态"
        />

        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="200"
        >
          <template slot-scope="{ row }">
            <el-button
              size="medium"
              type="text"
              @click="toDetail(row)"
              v-auth="'detail'"
            >
              查看
            </el-button>
            <el-button
              size="medium"
              type="text"
              @click="toEdit(row)"
              v-auth="'edit'"
              v-if="[2, 3].includes(row.status) && row.isInvalidated == 0"
            >
              编辑
            </el-button>
            <el-button
              size="medium"
              type="text"
              @click="toReminded(row)"
              v-if="row.status == 0"
            >
              催办
            </el-button>
            <el-button
              size="medium"
              type="text"
              @click="toCancel(row)"
              v-if="row.status == 0"
            >
              撤销
            </el-button>
            <el-button
              size="medium"
              type="text"
              v-auth="'confirm'"
              v-if="
                row.status == 1 &&
                row.deliveryFlag != 0 &&
                [1, 2, 4, 7].includes(row.type)
              "
              @click="
                confirmVisible = true
                currentItem = row
              "
            >
              {{ row.deliveryFlag == 1 ? '收货确认' : '收货详情' }}
            </el-button>
            <el-button
              size="medium"
              type="text"
              v-auth="'revoke'"
              v-if="row.status == 1 && row.invalidated === 1"
              @click="checkRevoke(row)"
            >
              作废
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
    <DialogConfirm
      @updateList="getData"
      :currentItem="currentItem"
      v-if="confirmVisible"
      :visible.sync="confirmVisible"
    />
    <DialogRevoke
      @updateList="getData"
      :currentItem="currentItem"
      v-if="revokeVisible"
      :visible.sync="revokeVisible"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
import DialogConfirm from './components/DialogConfirm.vue'
import DialogRevoke from './components/DialogRevoke.vue'
export default {
  components: { DialogConfirm, DialogRevoke },
  data() {
    return {
      confirmVisible: false,
      revokeVisible: false,
      params: {
        orderCode: '',
        type: '',
        status: ''
      }
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = this.$utils.deepClone(this.params)
        params.pageNum = params.pageNo
        delete params.pageNo
        const { total, list } = await this.$api.apply.getApply(params)
        this.tableData = (list || []).map(item => {
          const auditStatusStr = this.$constant.auditStatus.find(
            _ => _.value == item.status
          )?.label
          const typeStr = this.$constant.applyType.find(
            _ => _.value == item.type
          )?.label
          return { ...item, auditStatusStr, typeStr }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    toDetail(row) {
      this.$router.push({
        path: '/myAudit/detail',
        query: {
          processCode: row.processCode,
          processInstanceId: row.processId,
          type: row.type,
          from: 'myApply'
        }
      })
    },
    toEdit(row) {
      let url
      switch (row.type) {
        case 0:
          url = '/maintainApply'
          break
        case 1:
          url = '/standByApply'
          break
        case 2:
          url = '/partsApply'
          break
        case 3:
          url = '/partsReturnApply'
          break
        case 4:
          url = '/sellOrderApply'
          break
        case 5:
          url = '/convertApply'
          break
        case 6:
          url = '/storageOrderApply'
          break
        case 7:
          url = '/pickingOrderApply'
          break
        case 8:
          url = '/invoiceApply'
          break
      }
      if (!url) {
        this.$message.warning('该类型暂不支持编辑')
        return
      }
      this.$router.push({
        path: url,
        query: {
          orderCode: row.orderCode,
          processInstanceId: row.processId,
          type: row.type,
          edit: 1
        }
      })
    },
    async toReminded(row) {
      await this.$confirm(
        '催办申请将通过企微、站内消息的方式通知当前审批人进行处理，确定催办？',
        '催办',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      await this.$api.flowable.reminders(row.processId)
      this.$message.success('催办成功')
    },
    async toCancel(row) {
      await this.$confirm(
        '申请单撤销后，将不再继续审批流程，确定撤销？',
        '撤销',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
      await this.$api.flowable.revoke(row.processId)
      this.$message.success('撤销成功')
      this.getData()
    },
    async checkRevoke(row) {
      try {
        const result = await this.$api.apply.checkInvalidated({
          orderCode: row.orderCode
        })
        if (result) {
          this.revokeVisible = true
          this.currentItem = row
        } else {
          this.$message.error('该申请单不能作废！')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
